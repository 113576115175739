import React, { useEffect, useState } from "react";
import { ApplicationFormCheckbox } from "@components/ApplicationStepPage/ApplicationStepPage";
import { Typography } from "@components";
import * as styles from "./Summary.module.scss";
import { navigate } from "gatsby";
import { EditIcon } from "../../../Icons/Edit/EditIcon";
import { useHubspotSubmission } from "@helpers/Hooks";
import {StepData, useApplicationFormState, ApplicationFormState} from '@helpers/ApplicationFormHooks';
import cn from 'classnames';

type Props<T extends ApplicationFormState> = {
    formData: ReturnType<typeof useApplicationFormState<T>>;
    hubspotUrl: string;
    onSuccess: () => void;
};

export const useSummaryCommon = <T extends ApplicationFormState>({formData, hubspotUrl, onSuccess}: Props<T>)  => {
    const {formState, getInputProps, resetForm} = formData
	const [certified, setCertified] = useState(false);
	const [error, setError] = useState("");

	const { submitForm, submitStatus } = useHubspotSubmission(
		formState,
		hubspotUrl
	);

	useEffect(() => {
		if (submitStatus === "SUCCESS") {
            resetForm();
			onSuccess();
		} else if (submitStatus === "ERROR") {
			setError(
				"An error occurred. Please refresh the page and try again."
			);
		}
	}, [submitStatus]);

	const onSubmit = () => {
		if (!certified) {
			setError("*You must certify to submit the application");
			return;
		}
		setError("");
		submitForm();
	};

    return {
        onSubmit,
        submitStatus,
        getContactSection: (stepData: StepData) => (
            <>
                <SectionHeader stepData={stepData} />
                <FieldLine {...getInputProps("email" as any)} />
                <FieldLine {...getInputProps("firstname" as any)} />
                <FieldLine {...getInputProps("lastname" as any)} />
                <FieldLine {...getInputProps("jobtitle" as any)} />
                {(formState as any)["phone"] && <FieldLine {...getInputProps("phone" as any)} />}
            </>
        ),
        getCertification: () => (
            <>
            <div className={cn(styles.line, styles.divider)} />
        <div className={styles.certification}>
            <div className={styles.checkboxAndMessage}>
                <ApplicationFormCheckbox
                    value={certified}
                    onChange={(v) => {
                        setError("");
                        setCertified(v);
                    }}
                />
                <Typography variant="BODY" color="text-dark">
                    I certify that my organization meets all of the
                    above criteria, and recognize that Genemod may
                    revoke my application if it is deemed that my
                    organization does not meet this criteria.
                </Typography>
            </div>
            {error && (
                <Typography
                    className={styles.error}
                    color="medium-pink"
                >
                    {error}
                </Typography>
            )}

        </div></>),
    };
};

type SectionHeaderProps = {
	stepData: StepData;
};

export const SectionHeader = ({ stepData: { name, url } }: SectionHeaderProps) => (
	<div className={styles.sectionHeader}>
		<Typography className={styles.name}>{name}</Typography>
		<EditIcon onClick={() => navigate(url)} style={{ cursor: "pointer" }} />
		<div className={styles.line} />
	</div>
);

type FieldLineProps = {
	label: React.ReactNode;
	value: React.ReactNode;
};
export const FieldLine = ({ label, value }: FieldLineProps) => (
	<div className={styles.fieldLine}>
		<Typography className={styles.label} variant="BODY" color="text-dark">
			{label}
		</Typography>
		<Typography className={styles.value} variant="BODY" color="marine">
			{value}
		</Typography>
	</div>
);
