import React from "react";
import {
	useAcademiaFormState,
	ACADEMIA_FORM_STEPS,
} from "../_useAcademiaFormState";
import { AcademiaStepPage } from "../_AcademiaStepPage";
import {
	useSummaryCommon,
	SectionHeader,
	FieldLine,
} from "@components/ApplicationStepPage/common/Summary/useSummaryCommon";

const ACADEMIA_APP_ROUTE = `https://api.hsforms.com/submissions/v3/integration/submit/19551518/26cf0e44-fe2d-4af2-96d1-110d13a62086`;

const AcademiaSummary: React.FunctionComponent = () => {
	const formData = useAcademiaFormState(ACADEMIA_FORM_STEPS.SUMMARY.formStep);
	const { formState, getInputProps, navigateToStep } = formData;

	const { onSubmit, submitStatus, getContactSection, getCertification } =
		useSummaryCommon({
			formData,
			hubspotUrl: ACADEMIA_APP_ROUTE,
			onSuccess: () =>
				navigateToStep(ACADEMIA_FORM_STEPS.CONFIRMATION.formStep),
		});

	let websiteHref = formState["0-2/research_lab_website_url"];
	if (!formState["0-2/research_lab_website_url"]?.includes("http")) {
		websiteHref = "//" + websiteHref;
	}

	return (
		<AcademiaStepPage
			currentStepIndex={ACADEMIA_FORM_STEPS.SUMMARY.formStep}
			onButtonClick={onSubmit}
			onBackClick={() => navigateToStep(ACADEMIA_FORM_STEPS.USE.formStep)}
			isLoading={submitStatus === "SUBMITTING"}
		>
			<div>
				{getContactSection(ACADEMIA_FORM_STEPS.CONTACT)}
				<SectionHeader stepData={ACADEMIA_FORM_STEPS.TEAM} />
				<FieldLine {...getInputProps("0-2/research_lab_name")} />
				<FieldLine
					{...getInputProps("0-2/research_lab_website_url")}
					value={
						<a href={websiteHref} target="_blank">
							{formState["0-2/research_lab_website_url"]}
						</a>
					}
				/>
				<FieldLine {...getInputProps("0-2/research_lab_location")} />
				<FieldLine {...getInputProps("0-2/institution_name")} />
				<FieldLine {...getInputProps("team_size_range")} />
				{getCertification()}
			</div>
		</AcademiaStepPage>
	);
};

export default AcademiaSummary;
