import React from "react";
import {
	FormStep,
	FormReleaseVersion,
	useApplicationFormState,
} from "@helpers/ApplicationFormHooks";
import {
	ContactDetailsIcon,
	TeamDetailsIcon,
	UseForGenemodIcon,
	SummaryIcon,
} from "@components/ApplicationStepPage/ApplicationStepIcons";
import {
	USE_PAGE_LABELS,
	USE_PAGE_INITIAL_VALUES,
} from "@components/ApplicationStepPage/common/Use/useApplicationUsePageFields";
import {
	CONTACT_PAGE_LABELS,
	CONTACT_PAGE_INITIAL_VALUES,
} from "@components/ApplicationStepPage/common/Contact/useApplicationContactFields";

export const ACADEMIA_FORM_STEPS = {
	START: {
		formStep: 0 as FormStep,
		name: "N/A",
		getIcon: (_complete: boolean) => <></>,
		url: "/application/academia",
	},
	CONTACT: {
		formStep: 1 as FormStep,
		name: "Contact details",
		getIcon: (complete: boolean) => (
			<ContactDetailsIcon complete={complete} />
		),
		url: "/application/academia/contact",
	},
	TEAM: {
		formStep: 2 as FormStep,
		name: "Team details",
		getIcon: (complete: boolean) => <TeamDetailsIcon complete={complete} />,
		url: "/application/academia/team",
	},
	USE: {
		formStep: 3 as FormStep,
		name: "Use for Genemod",
		getIcon: (complete: boolean) => (
			<UseForGenemodIcon complete={complete} />
		),
		url: "/application/academia/use",
	},
	SUMMARY: {
		formStep: 4 as FormStep,
		name: "Summary",
		getIcon: (complete: boolean) => <SummaryIcon complete={complete} />,
		url: "/application/academia/summary",
	},
	CONFIRMATION: {
		formStep: 5 as FormStep,
		getIcon: (_complete: boolean) => <></>,
		name: "N/A",
		url: "/application/academia/confirmation",
	},
};

const INITIAL_ACADEMIA_FORM_STATE = {
	latestCompletedStep: ACADEMIA_FORM_STEPS.START.formStep,
	formReleaseVersion: 0 as FormReleaseVersion,
	// Contact page
	...CONTACT_PAGE_INITIAL_VALUES,
	// Team page
	"0-2/research_lab_name": "",
	"0-2/research_lab_website_url": "",
	"0-2/research_lab_location": "",
	"0-2/institution_name": "",
	team_size_range: "",
	// Use page
	...USE_PAGE_INITIAL_VALUES,
};

const ACADEMIA_FIELD_LABELS = {
	latestCompletedStep: "N/A",
	formReleaseVersion: "N/A",
	// Contact page
	...CONTACT_PAGE_LABELS,
	// Team page
	"0-2/research_lab_name": "Research lab name",
	"0-2/research_lab_website_url": "Research lab website",
	"0-2/research_lab_location": "Research lab location",
	"0-2/institution_name": "Institution name",
	team_size_range: "Team size",
	// Use page
	...USE_PAGE_LABELS,
};

/**
 * Cross page academia form state
 */
export const useAcademiaFormState = (currentStep: FormStep) =>
	useApplicationFormState({
		formName: "academiaForm",
		currentStep,
		initialState: INITIAL_ACADEMIA_FORM_STATE,
		labels: ACADEMIA_FIELD_LABELS,
		stepsData: ACADEMIA_FORM_STEPS,
	});
