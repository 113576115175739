// extracted by mini-css-extract-plugin
export var certification = "Summary-module--certification--nRevu";
export var checkboxAndMessage = "Summary-module--checkboxAndMessage--d-f8u";
export var divider = "Summary-module--divider--lLbM-";
export var error = "Summary-module--error--hv5Jq";
export var fieldLine = "Summary-module--fieldLine--MnJf7";
export var label = "Summary-module--label--rGUcy";
export var limitWidthOnLargeScreens = "Summary-module--limitWidthOnLargeScreens--e4-4a";
export var line = "Summary-module--line--Ow6cX";
export var name = "Summary-module--name--J-bno";
export var sectionHeader = "Summary-module--sectionHeader--z4+wD";
export var value = "Summary-module--value--HH8wh";