import React from "react";
import { FormStep } from "@helpers/ApplicationFormHooks";
import { ApplicationStepPage } from "@components/ApplicationStepPage/ApplicationStepPage";
import { ACADEMIA_FORM_STEPS } from "./_useAcademiaFormState";
import SEO from "@components/PageLayout/SEO";
import { PageLayoutMetaData } from "@components/PageLayout/_PageLayoutMetaData";

const SIDEBAR_STEPS = [
	ACADEMIA_FORM_STEPS.CONTACT,
	ACADEMIA_FORM_STEPS.TEAM,
	ACADEMIA_FORM_STEPS.USE,
	ACADEMIA_FORM_STEPS.SUMMARY,
];

type AcademiaStepPageProps = {
	currentStepIndex: FormStep;
	isLoading?: boolean;
	onButtonClick: () => void;
	onBackClick?: () => void;
	children: React.ReactNode;
};

export const AcademiaStepPage = (props: AcademiaStepPageProps) => (
	<>
	<SEO title={PageLayoutMetaData.academia.title[props.currentStepIndex]} />
		<ApplicationStepPage
			pageTitle="Genemod's Academia Program"
			steps={SIDEBAR_STEPS}
			{...props}
		/>
	</>
);
