import React from "react";
import cn from "classnames";
import * as styles from "./EditIcon.module.scss";

export const EditIcon = (
	props: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>
) => (
	<div {...props} className={cn(styles.editIcon, props.className)}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={14}
			height={14}
			viewBox="0 0 15.96 16.04"
		>
			<defs></defs>
			<title>Asset 237</title>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Icons">
					<path
						fill="var(--marine)"
						d="M6.06,7.41,5.85,9.87A.15.15,0,0,0,6,10l2.47-.18a.58.58,0,0,0,.36-.17l5.21-5.16a.24.24,0,0,0,0-.35L11.79,1.86a.24.24,0,0,0-.35,0L6.23,7.05A.58.58,0,0,0,6.06,7.41Z"
					/>
					<path
						fill="var(--marine)"
						d="M13.55-.12h.87a1,1,0,0,1,1,1V2.59a1,1,0,0,1-1,1h-.87a.5.5,0,0,1-.5-.5V.38a.5.5,0,0,1,.5-.5Z"
						transform="translate(2.9 10.52) rotate(-44.71)"
					/>
					<path
						fill="var(--marine)"
						d="M.91,16A.91.91,0,0,1,0,15.13V2.94A.91.91,0,0,1,.91,2H7.5a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5H1V15H13V8.53a.5.5,0,0,1,1,0v6.6a.9.9,0,0,1-.9.9Z"
					/>
				</g>
			</g>
		</svg>
	</div>
);
